<template>
  <div>
    <stepNav name="Alternativ" :validStep="stepIsValid"/>
    <div class="form-group">
      <radioButtonGroup
        :label="$t('alternatives.accessory_color')"
        :options="accessoryColors"
        v-model="accessoryColor"
      />
    </div>

    <div class="form-group" v-if="selectedSnowProtection">
      <b-field :label="$t('aternatives.roof_angle')">
        <b-input
          type="number"
          :min="roofTiltOptions.min"
          :max="roofTiltOptions.max"
          :step="roofTiltOptions.step"
          v-model="roofTilt"
          :icon-right="undefined"
          expanded
        ></b-input>
        <span class="input-suffix">°</span>
      </b-field>
    </div>

    <div class="form-group" v-if="selectedSnowProtection">
       <b-field :label="$t('alternatives.roof_pitch')" append="m">
          <b-input
            type="number"
            :min="roofLengthOptions.min"
            :max="roofLengthOptions.max"
            :step="roofLengthOptions.step"
            v-model="roofLength"
            expanded
          ></b-input>
          <span class="input-suffix">m</span>
        </b-field>
    </div>

    <div class="form-group" v-if="selectedSnowProtection">
      <radioButtonGroup
        v-if="selectedSnowProtection"
        :label="$t('alternatives.snow_zone')"
        :options="snowZones"
        v-model="snowZone"
      />
      <snowZoneMap/>
    </div>

    <b-field :label="$t('alternatives.distance_between_consoles')" v-if="selectedSnowProtection && distanceConsole">
      <div>
        {{distanceConsole ? $t('alternatives.calculted_distance_between_consoles', { distance: distanceConsole }) : null}} <br>
        <span class="form-group is-warning" v-if="rowsSnowprotection > 1">
          {{ $t('alternatives.snow_zone_warning', { multiplier: rowsSnowprotection }) }}
        </span>
      </div>
    </b-field>
  </div>
</template>

<script>
import stepNav from '../stepNav'
import snowZoneMap from '../snowzoneMap'
import productCalcUtil from '../../mixins/productCalcUtil'
import stepValidation from '../../mixins/stepValidation'

export default {
  name: 'alternatives',
  components: {
    stepNav,
    snowZoneMap,
    radioButtonGroup: () => import("../radioButtonGroup"),
  },
  mixins: [
    productCalcUtil,
    stepValidation
  ],
  data: function () {
    return {
      snowZones: ["1","1.5","2","2.5","3","3.5","4.5","5.5"],
      accessoryColors: ["Galvad", "Svart", "Tegelröd"],
      roofLengthOptions: {
        min:1,
        max:100,
        step: 0.1
      },
    }
  },
  computed: {
    stepIsValid() {
      return this.isStepComplete(this.selectedSnowProtection ? ['roofTilt', 'snowZone', 'roofLength', 'accessoryColor'] : ['accessoryColor'], null, this.fieldValidationErrors )
    },
    roofTiltOptions() {
      let minimum = 14

      if (this.rooftile && this.rooftile.name.includes('Carisma')) minimum = 18
      if (this.rooftile && this.rooftile.name.includes('Höganäs')) minimum = 22

      return {
      min:minimum,
      max:60,
      step: 1
      }
    },
    fieldValidationErrors: {
      get() {return this.$store.getters.getFieldValidationErrors},
      set(value) {this.$store.dispatch('updateFieldValidationErrors', value)}
    },
    roofTilt: {
      get() {return this.$store.getters.getSelectedValue('roofTilt')},
      set(value) {this.$store.dispatch('updateValue', {name: 'roofTilt', value: value})}
    },
    snowZone: {
      get() {return this.$store.getters.getSelectedValue('snowZone')},
      set(value) {this.$store.dispatch('updateValue', {name: 'snowZone', value: value})}
    },
    roofLength: {
      get() {return this.$store.getters.getSelectedValue('roofLength')},
      set(value) {this.$store.dispatch('updateValue', {name: 'roofLength', value: value})}
    },
    accessoryColor: {
      get() {
        let color = this.$store.getters.getSelectedValue('accessoryColor')
        if (color === null) this.defaultColor(color)
        return color
      },
      set(value) {this.$store.dispatch('updateValue', {name: 'accessoryColor', value: value})}
    },
  },
  methods: {
    goToPrevStep() {
      this.$store.dispatch('previousStep')
    },
    goToNextStep() {
      this.$store.dispatch('nextStep')
    },
    markFormatter(value) {
      return {
          label: `${value}`
      }
    },
    defaultColor(selectedColor) {
      let color
      let colorNum
      if (!this.rooftile || !this.rooftile.artNumber) return
      colorNum = this.rooftile.artNumber.slice(-2)
      switch(colorNum) {
        case "14":
        case "16":
        case "19":
        case "24":
        case "26":
        case "29":
        case "55":
        case "84":
        case "86":
        color = "Tegelröd";
      break
        case "01":
        case "20":
        case "21":
        case "22":
        case "23":
        case "31":
        case "48":
        case "51":
        case "80":
        case "82":
        case "83":
        color = "Svart";
      break
        case "25":
        case "28":
        case "37":
        color = "Galvad";
      break
        default:
          color = selectedColor
      }
      this.$store.dispatch('updateValue', {name: 'accessoryColor', value: color})
      return color
    }
  },
  watch: {
    roofLength(value) {
      let errArr = [...this.fieldValidationErrors]
      let index = errArr.indexOf('roofLength')
      let valid = true
      if (value && (value < this.roofLengthOptions.min || value > this.roofLengthOptions.max)) {
        valid = false
      }

      if (valid && index >= 0) errArr.splice(index, 1)
      if (!valid && index === -1) errArr.push('roofLength')
      this.fieldValidationErrors = errArr
    },
    roofTilt(value) {
      let errArr = [...this.fieldValidationErrors]
      let index = errArr.indexOf('roofTilt')
      let valid = true
      if (value && (value < this.roofTiltOptions.min || value > this.roofTiltOptions.max)) {
        valid = false
      }

      if (valid && index >= 0) errArr.splice(index, 1)
      if (!valid && index === -1) errArr.push('roofTilt')
      this.fieldValidationErrors = errArr
    },
  },
  created() {
    if (!this.selectedSnowProtection) {
      this.$store.dispatch('resetValue', 'roofTilt')
      this.$store.dispatch('resetValue', 'snowZone')
      this.$store.dispatch('resetValue', 'roofLength')
    }
  }
}
</script>

<style scoped>
.field taginput-container.is-focusable {
  border-radius: 0px;
  border: 2px solid rgb(209, 211, 214)!;
}
label {
  display: inline-block;
  width: 100%;
  color: #464749;
  font-size: 1rem;
  font-weight: 700;
  text-align: right;
  padding: 0 5px;
}
.is-warning {
  /* color: rgb(166,25,46); */
  font-weight: bold;
}
.form-group {
  margin: 15px;
}
.help-text {
  text-align: left
}
.radiobtn label {
  background-color: blue;
}

.input-suffix {
  font-size: 1.3rem;
  line-height: 3rem;
  padding-left: 0.5rem;
}
</style>
