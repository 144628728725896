export default {
  data() {
    return {}
  },
  computed: {},
  methods: {
    isStepComplete(nextCriteria, evalCriteria, evalErrors) {
      let approvedCriterias = []
      if (evalErrors && evalErrors.length > 0) {
        return false
      }
      if (nextCriteria === undefined || nextCriteria.length === 0) {
        return true
      }

      nextCriteria.forEach(condition => {
        let selectedValue = this.$store.getters.getSelectedValue(condition)
        if (selectedValue === null || selectedValue === '') return

        if (['string', 'number', 'boolean'].includes(typeof selectedValue)) {
          if (selectedValue !== "0" && selectedValue !== 0) approvedCriterias.push(condition)
        }
        else if (selectedValue.length > 0 && selectedValue !== "0") {
          approvedCriterias.push(condition)
        }
      })
      if (evalCriteria === 'single' && approvedCriterias.length === 0) {
        return false
      } else if (evalCriteria !== 'single' && approvedCriterias.length < nextCriteria.length) {
        return false 
      } else {
        return true
      } 
    },
  }
} 